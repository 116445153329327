<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4032 10V19.5H10.483L10.4833 19.4915L9.95384 19.4999L9.94773 19.5C4.78468 19.4979 0.585844 15.3334 0.501301 10.1593L0.5 9.99801C0.501071 4.74993 4.73457 0.5 9.9516 0.5C15.1693 0.5 19.4032 4.751 19.4032 10Z"
      stroke="currentColor"
      stroke-opacity="0.87"
    />
    <path
      clip-rule="evenodd"
      d="M11.9355 17.9307C12.0434 17.9307 12.4744 17.9307 13.2284 17.9307C13.2284 17.0907 13.2284 16.6165 13.2284 16.5081V15.8678C13.2284 15.7052 13.1695 15.5647 13.0518 15.4464C12.934 15.3281 12.7942 15.2689 12.6324 15.2689H11.2858C10.653 15.2689 10.2079 15.1395 9.95032 14.8807C9.69278 14.6219 9.56402 14.2485 9.56402 13.7605V9.058H12.4116C12.5195 9.058 12.7182 9.058 13.0076 9.058C13.0076 8.76718 13.0076 8.56754 13.0076 8.4591V6.9951C13.0076 6.83243 12.9488 6.69194 12.831 6.57364C12.7133 6.45534 12.5735 6.39619 12.4116 6.39619H9.56402V2.78055C9.56402 2.61788 9.50515 2.4774 9.38742 2.3591C9.26969 2.24079 9.12988 2.18164 8.968 2.18164H8.14184C7.34241 2.18164 6.69434 2.83286 6.69434 3.63619V6.39619V9.058V13.8271C6.69434 15.1876 7.06224 16.2116 7.79806 16.8993C8.53387 17.5869 9.60081 17.9307 10.9989 17.9307H11.9355Z"
      fill="currentColor"
      fill-opacity="0.87"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
